module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MSF Blog","short_name":"MSF Blog","start_url":"/","background_color":"#ffffff","theme_color":"#fbbe00","display":"standalone","icon":"src/images/icons/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b35e6bb24fec1ac4f2ed0d08186a804"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-76218479-2","head":false,"anonymize":true,"respectDNT":true,"exclude":["/admin/**"],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    }]
