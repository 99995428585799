// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-altrui-it-js": () => import("./../../../src/pages/altrui.it.js" /* webpackChunkName: "component---src-pages-altrui-it-js" */),
  "component---src-pages-autoconhecimento-pt-js": () => import("./../../../src/pages/autoconhecimento.pt.js" /* webpackChunkName: "component---src-pages-autoconhecimento-pt-js" */),
  "component---src-pages-autoconocimiento-es-js": () => import("./../../../src/pages/autoconocimiento.es.js" /* webpackChunkName: "component---src-pages-autoconocimiento-es-js" */),
  "component---src-pages-bem-estar-fisico-pt-js": () => import("./../../../src/pages/bem-estar-fisico.pt.js" /* webpackChunkName: "component---src-pages-bem-estar-fisico-pt-js" */),
  "component---src-pages-benessere-fisico-it-js": () => import("./../../../src/pages/benessere-fisico.it.js" /* webpackChunkName: "component---src-pages-benessere-fisico-it-js" */),
  "component---src-pages-benessere-mentale-it-js": () => import("./../../../src/pages/benessere-mentale.it.js" /* webpackChunkName: "component---src-pages-benessere-mentale-it-js" */),
  "component---src-pages-bienestar-fisico-es-js": () => import("./../../../src/pages/bienestar-fisico.es.js" /* webpackChunkName: "component---src-pages-bienestar-fisico-es-js" */),
  "component---src-pages-conoscenza-di-se-it-js": () => import("./../../../src/pages/conoscenza-di-se.it.js" /* webpackChunkName: "component---src-pages-conoscenza-di-se-it-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contactanos-es-js": () => import("./../../../src/pages/contactanos.es.js" /* webpackChunkName: "component---src-pages-contactanos-es-js" */),
  "component---src-pages-contate-nos-pt-js": () => import("./../../../src/pages/contate-nos.pt.js" /* webpackChunkName: "component---src-pages-contate-nos-pt-js" */),
  "component---src-pages-contattaci-it-js": () => import("./../../../src/pages/contattaci.it.js" /* webpackChunkName: "component---src-pages-contattaci-it-js" */),
  "component---src-pages-contribuir-pt-js": () => import("./../../../src/pages/contribuir.pt.js" /* webpackChunkName: "component---src-pages-contribuir-pt-js" */),
  "component---src-pages-contribuire-it-js": () => import("./../../../src/pages/contribuire.it.js" /* webpackChunkName: "component---src-pages-contribuire-it-js" */),
  "component---src-pages-contribute-en-js": () => import("./../../../src/pages/contribute.en.js" /* webpackChunkName: "component---src-pages-contribute-en-js" */),
  "component---src-pages-contribuye-es-js": () => import("./../../../src/pages/contribuye.es.js" /* webpackChunkName: "component---src-pages-contribuye-es-js" */),
  "component---src-pages-hear-me-too-en-js": () => import("./../../../src/pages/hear-me-too.en.js" /* webpackChunkName: "component---src-pages-hear-me-too-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-informazioni-legali-it-js": () => import("./../../../src/pages/informazioni-legali.it.js" /* webpackChunkName: "component---src-pages-informazioni-legali-it-js" */),
  "component---src-pages-legal-notice-en-js": () => import("./../../../src/pages/legal-notice.en.js" /* webpackChunkName: "component---src-pages-legal-notice-en-js" */),
  "component---src-pages-mental-health-en-js": () => import("./../../../src/pages/mental-health.en.js" /* webpackChunkName: "component---src-pages-mental-health-en-js" */),
  "component---src-pages-mente-sana-es-js": () => import("./../../../src/pages/mente-sana.es.js" /* webpackChunkName: "component---src-pages-mente-sana-es-js" */),
  "component---src-pages-ni-una-menos-es-js": () => import("./../../../src/pages/ni-una-menos.es.js" /* webpackChunkName: "component---src-pages-ni-una-menos-es-js" */),
  "component---src-pages-nota-legal-es-js": () => import("./../../../src/pages/nota-legal.es.js" /* webpackChunkName: "component---src-pages-nota-legal-es-js" */),
  "component---src-pages-others-en-js": () => import("./../../../src/pages/others.en.js" /* webpackChunkName: "component---src-pages-others-en-js" */),
  "component---src-pages-otros-es-js": () => import("./../../../src/pages/otros.es.js" /* webpackChunkName: "component---src-pages-otros-es-js" */),
  "component---src-pages-outros-pt-js": () => import("./../../../src/pages/outros.pt.js" /* webpackChunkName: "component---src-pages-outros-pt-js" */),
  "component---src-pages-physical-wellbeing-en-js": () => import("./../../../src/pages/physical-wellbeing.en.js" /* webpackChunkName: "component---src-pages-physical-wellbeing-en-js" */),
  "component---src-pages-por-nossa-voz-pt-js": () => import("./../../../src/pages/por-nossa-voz.pt.js" /* webpackChunkName: "component---src-pages-por-nossa-voz-pt-js" */),
  "component---src-pages-quella-volta-che-it-js": () => import("./../../../src/pages/quella-volta-che.it.js" /* webpackChunkName: "component---src-pages-quella-volta-che-it-js" */),
  "component---src-pages-reflections-en-js": () => import("./../../../src/pages/reflections.en.js" /* webpackChunkName: "component---src-pages-reflections-en-js" */),
  "component---src-pages-reflexiones-es-js": () => import("./../../../src/pages/reflexiones.es.js" /* webpackChunkName: "component---src-pages-reflexiones-es-js" */),
  "component---src-pages-reflexoes-pt-js": () => import("./../../../src/pages/reflexões.pt.js" /* webpackChunkName: "component---src-pages-reflexoes-pt-js" */),
  "component---src-pages-representante-legal-pt-js": () => import("./../../../src/pages/representante-legal.pt.js" /* webpackChunkName: "component---src-pages-representante-legal-pt-js" */),
  "component---src-pages-riflessioni-it-js": () => import("./../../../src/pages/riflessioni.it.js" /* webpackChunkName: "component---src-pages-riflessioni-it-js" */),
  "component---src-pages-saude-mental-pt-js": () => import("./../../../src/pages/saude-mental.pt.js" /* webpackChunkName: "component---src-pages-saude-mental-pt-js" */),
  "component---src-pages-self-awareness-en-js": () => import("./../../../src/pages/self-awareness.en.js" /* webpackChunkName: "component---src-pages-self-awareness-en-js" */),
  "component---src-pages-sobre-nos-pt-js": () => import("./../../../src/pages/sobre-nos.pt.js" /* webpackChunkName: "component---src-pages-sobre-nos-pt-js" */),
  "component---src-pages-sobre-nosotros-es-js": () => import("./../../../src/pages/sobre-nosotros.es.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-es-js" */),
  "component---src-pages-su-di-noi-it-js": () => import("./../../../src/pages/su-di-noi.it.js" /* webpackChunkName: "component---src-pages-su-di-noi-it-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

